import React, { useRef, useEffect } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Card,
    CardContent,
    Avatar,
    Stack,
    Chip,
    Divider,
    Container,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import CakeIcon from '@mui/icons-material/Cake';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';


import { Line } from 'react-chartjs-2';

import { useTranslation } from 'react-i18next';


const ECGAnalysisModal = ({ rPeaks = [], voltageMeasurements = [], samplingFrequency = 1, patient = {} }) => {
    const rrIntervals = [];
    const { t } = useTranslation();


    for (let i = 1; i < rPeaks.length; i++) {
        const interval = (rPeaks[i].time - rPeaks[i - 1].time).toFixed(4); // Calculer l'intervalle entre deux R-Peaks
        rrIntervals.push(parseFloat(interval));
    }

    // Générer les labels de temps pour chaque intervalle, en utilisant rPeaks[i] pour chaque rrInterval
    const labels = rPeaks.slice(1).map((peak) => {
        const timeInSeconds = peak.time.toFixed(1); // Utiliser directement `time` car il est déjà en secondes
        return `${timeInSeconds} s`;
    });

    // Afficher les labels dans la console pour vérification

    // Préparer les données pour Chart.js
    const data = {
        labels: labels,
        datasets: [
            {
                label: 'R-R Intervals (s)',
                data: rrIntervals.map(interval => interval * 1000), // Multiplie chaque élément par 1000
                fill: false,
                borderColor: 'rgba(75, 192, 192, 1)',
                tension: 0.1,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time (s)',
                },
            },
            y: {
                title: {
                    display: true,
                    text: t('ecg.intervalDuration'),
                },
                min: 200,
                max: 1500,
            },
        },
    };

    const gridCanvasRef = useRef([]);
    const curveCanvasRef = useRef([]);

    useEffect(() => {
        // Dessiner le quadrillage de fond papier millimétré
        const drawGrid = (ctx, width, height, index) => {
            // Paramètres de la grille
            const mmPerSecond = 25; // 25 mm par seconde
            const mmPerMV = 10; // 10 mm par mV (standard pour l'amplitude)

            // Grille horizontale (temps)
            const totalSecondsPerLine = 10; // Chaque ligne de grille représente 10 secondes
            const totalMillimetersX = totalSecondsPerLine * mmPerSecond; // Total de millimètres sur l'axe X
            const pixelsPerMillimeterX = width / totalMillimetersX; // Pixels par millimètre sur l'axe X
            const majorGridSizeX = 5 * pixelsPerMillimeterX; // Grands carrés : 5 mm (chaque grand carré représente 0.2s)
            const minorGridSizeX = pixelsPerMillimeterX;     // Petits carrés : 1 mm (0.04s)

            // Grille verticale (amplitude) - Fixer à 10 mm par mV pour une plage de -2 mV à 2 mV
            const yMin = -2; // -2 mV
            const yMax = 2;  // 2 mV
            const totalMillimetersY = (yMax - yMin) * mmPerMV; // Total de millimètres nécessaires en hauteur
            const pixelsPerMillimeterY = height / totalMillimetersY;

            // Espacement des lignes principales et secondaires sur l'axe Y
            const majorGridSizeY = 10 * pixelsPerMillimeterY; // Grands carrés : 10 mm pour 1 mV
            const minorGridSizeY = pixelsPerMillimeterY;      // Petits carrés : 1 mm pour 0.1 mV

            // Décalage pour les labels de l'axe X
            const xLabelOffset = 50;

            // Dessiner les lignes du quadrillage
            ctx.clearRect(0, 0, width, height);

            // Dessiner les grandes lignes du quadrillage (horizontal et vertical)
            ctx.strokeStyle = 'rgba(255, 165, 0, 0.8)';  // Orange pour les grands carrés (lignes majeures)
            ctx.lineWidth = 1;
            for (let x = 0; x <= width; x += majorGridSizeX) {
                ctx.beginPath();
                ctx.moveTo(x + xLabelOffset, 0);
                ctx.lineTo(x + xLabelOffset, height);
                ctx.stroke();
            }
            for (let y = 0; y <= height; y += majorGridSizeY) {
                ctx.beginPath();
                ctx.moveTo(xLabelOffset, y);
                ctx.lineTo(width + xLabelOffset, y);
                ctx.stroke();
            }

            // Dessiner les petites lignes du quadrillage (horizontal et vertical)
            ctx.strokeStyle = 'rgba(255, 182, 107, 0.5)';  // Orange plus clair pour les petits carrés (lignes mineures)
            ctx.lineWidth = 0.5;
            for (let x = 0, countX = 0; x <= width; x += minorGridSizeX, countX++) {
                ctx.strokeStyle = (countX % 5 === 0) ? 'rgba(255, 165, 0, 0.8)' : 'rgba(255, 182, 107, 0.5)';
                ctx.lineWidth = (countX % 5 === 0) ? 1 : 0.5;
                ctx.beginPath();
                ctx.moveTo(x + xLabelOffset, 0);
                ctx.lineTo(x + xLabelOffset, height);
                ctx.stroke();
            }
            for (let y = 0, countY = 0; y <= height; y += minorGridSizeY, countY++) {
                ctx.strokeStyle = (countY % 5 === 0) ? 'rgba(255, 165, 0, 0.8)' : 'rgba(255, 182, 107, 0.5)';
                ctx.lineWidth = (countY % 5 === 0) ? 1 : 0.5;
                ctx.beginPath();
                ctx.moveTo(xLabelOffset, y);
                ctx.lineTo(width + xLabelOffset, y);
                ctx.stroke();
            }

            // Ajouter les labels de l'axe X (temps)
            ctx.fillStyle = 'black';
            ctx.font = '12px Arial';
            const timeOffset = index * 10; // Décalage des labels en fonction de l'index (10s, 20s, etc.)

            // Afficher uniquement les labels aux secondes pleines
            for (let x = 0; x <= width; x += majorGridSizeX * 5) { // Sauter tous les 5 grands carrés (chaque grand carré = 0,2s, donc 5 grands carrés = 1s)
                const timeLabel = (x / (majorGridSizeX * 5)) + timeOffset;
                ctx.fillText(`${timeLabel} s`, x + xLabelOffset, height - 5);
            }

            // Ajouter les labels de l'axe Y (en mV) pour une plage de -2 mV à 2 mV
            for (let y = 0; y <= height; y += majorGridSizeY) {
                const voltageLabel = (yMax - (y / height) * (yMax - yMin)).toFixed(1);
                ctx.fillText(`${voltageLabel} mV`, 2, y - 2);
            }
        };

        gridCanvasRef.current.forEach((canvas, index) => {
            if (canvas) {
                canvas.width = canvas.parentElement.clientWidth; // Utiliser la largeur du parent pour s'assurer qu'il remplit l'espace
                const gridCtx = canvas.getContext('2d');
                drawGrid(gridCtx, canvas.width, canvas.height, index);
            }
        });
    }, []);


    useEffect(() => {
        // Dessiner la courbe ECG
        const drawCurve = (ctx, width, height, dataPoints) => {
            ctx.clearRect(0, 0, width, height);

            // Décalage horizontal pour éviter la superposition sur les labels Y
            const xOffset = 50;

            // Durée visible de la courbe (10 secondes)
            const visibleDuration = 10; // En secondes

            // Calculer le nombre de points visibles
            const totalPointsVisible = Math.floor(visibleDuration * samplingFrequency);

            // Échelle horizontale pour ajuster la largeur totale du graphique
            const xScale = (width - xOffset) / totalPointsVisible;

            // Fixer les limites pour l'axe Y (de -2 mV à 2 mV)
            const yMin = -2;  // -2 mV
            const yMax = 2;   // 2 mV

            // Échelle verticale ajustée pour que l'axe Y aille de yMin à yMax
            const yScale = height / (yMax - yMin);

            // Positionner le 0V au milieu
            const yOffset = height * (yMax / (yMax - yMin));

            ctx.strokeStyle = 'red';
            ctx.lineWidth = 2;
            ctx.beginPath();

            // Calculer l'indice de départ et de fin en fonction du `panOffset`
            const startIndex = 0;
            const endIndex = Math.min(dataPoints.length, startIndex + totalPointsVisible);

            // Dessiner la courbe ECG
            dataPoints.slice(startIndex, endIndex).forEach((point, index) => {
                // Prendre en compte l'offset de panoramique et le décalage fixe (xOffset)
                const x = xOffset + (index * xScale);
                const y = yOffset - (point * 1000 * yScale); // Conversion de V à mV et ajustement avec yOffset

                if (index === 0) {
                    ctx.moveTo(x, y);
                } else {
                    ctx.lineTo(x, y);
                }
            });
            ctx.stroke();
        };

        curveCanvasRef.current.forEach((canvas, index) => {
            if (canvas) {
                const curveCtx = canvas.getContext('2d');
                canvas.width = canvas.parentElement.clientWidth; // Utiliser la largeur du parent pour s'assurer qu'il remplit l'espace

                // Calculer les indices de départ et de fin pour chaque segment de 10 secondes
                const startIndex = index * samplingFrequency * 10;
                const endIndex = startIndex + samplingFrequency * 10;

                // Créer les `dataPoints` à partir des mesures de tension (voltageMeasurements)
                const dataPoints = voltageMeasurements.slice(startIndex, endIndex).map((item) => item.voltage);

                // Dessiner la courbe sur le canvas actuel
                drawCurve(curveCtx, canvas.width, canvas.height, dataPoints);
            }
        });
    }, [voltageMeasurements, samplingFrequency]);


    return (
        <Box width="100%" height="600px" overflow="auto">

            {/* Accordion pour les détails du patient */}
            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">{t('patients.patientDetails')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="row" spacing={2} flexWrap="wrap">
                        <Card sx={{ minWidth: 200, flex: '1 1 calc(33% - 16px)', marginBottom: 2, borderRadius: 4 }}>
                            <CardContent>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ bgcolor: '#007FFF' }}>
                                        <PersonIcon />
                                    </Avatar>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {t('patients.patientCode')}
                                        </Typography>
                                        <Typography variant="body1">{patient.patientCode || '-'}</Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 200, flex: '1 1 calc(33% - 16px)', marginBottom: 2, borderRadius: 4 }}>
                            <CardContent>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ bgcolor: '#FF5722' }}>
                                        <CakeIcon />
                                    </Avatar>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {t('patients.dateOfBirth')}
                                        </Typography>
                                        <Typography variant="body1">{patient.dateOfBirth || '-'}</Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                        <Card sx={{ minWidth: 200, flex: '1 1 calc(33% - 16px)', marginBottom: 2, borderRadius: 4 }}>
                            <CardContent>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ bgcolor: '#4CAF50' }}>
                                        <FavoriteIcon />
                                    </Avatar>
                                    <Box>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            {t('patients.sex')}
                                        </Typography>
                                        <Typography variant="body1">{patient.gender ? t(`patients.${patient.gender}`) : '-'}</Typography>
                                    </Box>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Stack>

                    {/* Box pour les informations supplémentaires */}
                    <Box sx={{ mt: 4, mb: 4 }}>
                        <Card sx={{ borderRadius: 4, p: 3, boxShadow: 3, backgroundColor: '#ffffff' }}>
                            <CardContent>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <Avatar sx={{ bgcolor: '#f50057' }}>
                                        <MedicalServicesIcon />
                                    </Avatar>
                                    <Typography variant="h6" gutterBottom>
                                        {t('patients.additionalInformation')}
                                    </Typography>
                                </Stack>
                                <Divider sx={{ mb: 3, mt: 2 }} />
                                <Container maxWidth="lg">
                                    <Stack direction={{ xs: 'column', md: 'row' }} spacing={4}>
                                        <Box flex={1}>
                                            <Box>
                                                <Typography variant="subtitle1" color="primary" gutterBottom>
                                                    {t('patients.symptoms')}
                                                </Typography>
                                                {patient.symptoms?.length ? (
                                                    <Stack direction="row" spacing={1}>
                                                        {patient.symptoms.map((symptom, index) => (
                                                            <Chip key={index} label={symptom} color="primary" variant="outlined" />
                                                        ))}
                                                    </Stack>
                                                ) : (
                                                    <Typography variant="body1" color="textSecondary">-</Typography>
                                                )}
                                            </Box>
                                            <Box sx={{ mt: 3 }}>
                                                <Typography variant="subtitle1" color="primary" gutterBottom>
                                                    {t('patients.durationOfCrises')}
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary">
                                                    {patient.crisisDuration ? `${patient.crisisDuration} ${t(`patients.${patient.crisisDurationUnit}`)}` : '-'}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ mt: 3 }}>
                                                <Typography variant="subtitle1" color="primary" gutterBottom>
                                                    {t('patients.prescriptions')}
                                                </Typography>
                                                {patient.prescriptionDrugs?.length ? (
                                                    <Stack direction="row" spacing={1}>
                                                        {patient.prescriptionDrugs.map((drug, index) => (
                                                            <Chip key={index} label={drug} color="secondary" variant="outlined" />
                                                        ))}
                                                    </Stack>
                                                ) : (
                                                    <Typography variant="body1" color="textSecondary">-</Typography>
                                                )}
                                            </Box>
                                        </Box>
                                        <Box flex={1}>
                                            <Box>
                                                <Typography variant="subtitle1" color="primary" gutterBottom>
                                                    {t('patients.maneuversToStopCrises')}
                                                </Typography>
                                                {patient.maneuvers?.length ? (
                                                    <Stack direction="row" spacing={1}>
                                                        {patient.maneuvers.map((maneuver, index) => (
                                                            <Chip key={index} label={maneuver} color="success" variant="outlined" />
                                                        ))}
                                                    </Stack>
                                                ) : (
                                                    <Typography variant="body1" color="textSecondary">-</Typography>
                                                )}
                                                {patient.maneuverTiming && (
                                                    <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                                                        {t('patients.whenManeuverEffective')}: {t(`patients.${patient.maneuverTiming}`)}
                                                    </Typography>
                                                )}
                                            </Box>
                                            {patient.exams && Object.keys(patient.exams).length > 0 && (
                                                <Box sx={{ mt: 3 }}>
                                                    <Typography variant="subtitle1" color="primary" gutterBottom>
                                                        {t('patients.complementaryExams')}
                                                    </Typography>
                                                    {Object.keys(patient.exams).map((examKey) => (
                                                        patient.exams[examKey] && patient.exams[`${examKey}Conclusion`] && (
                                                            <Box key={examKey} sx={{ mt: 1 }}>
                                                                <Typography variant="body1" gutterBottom color="textSecondary">
                                                                    {t(`patients.exams.${examKey}`)}
                                                                </Typography>
                                                                <Typography variant="body1" gutterBottom>
                                                                    {patient.exams[`${examKey}Conclusion`]}
                                                                </Typography>
                                                            </Box>
                                                        )
                                                    ))}

                                                </Box>
                                            )}
                                        </Box>
                                    </Stack>
                                </Container>
                            </CardContent>
                        </Card>
                    </Box>



                </AccordionDetails>
            </Accordion>


            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">ECG sur 30 secondes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ mt: 4, mb: 4 }}>
                        <Card sx={{ borderRadius: 4, p: 3, boxShadow: 3, backgroundColor: '#ffffff' }}>
                            <CardContent>
                                <Box width="100%" display="flex" flexDirection="column" alignItems="center">
                                    {[0, 1, 2].map((rowIndex) => (
                                        <Box key={rowIndex} position="relative" width="100%" height="150px" marginBottom="16px">
                                            <canvas
                                                ref={(el) => (gridCanvasRef.current[rowIndex] = el)}
                                                width={800}
                                                height={150}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    zIndex: 1,
                                                }}
                                            />
                                            <canvas
                                                ref={(el) => (curveCanvasRef.current[rowIndex] = el)}
                                                width={800}
                                                height={150}
                                                style={{
                                                    position: 'absolute',
                                                    top: 0,
                                                    left: 0,
                                                    zIndex: 2,
                                                }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </AccordionDetails>
            </Accordion>



            <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5">Graphique des intervalles R-R</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{ mt: 4, mb: 4 }}>
                        <Card sx={{ borderRadius: 4, p: 3, boxShadow: 3, backgroundColor: '#ffffff' }}>
                            <CardContent>
                                <Box width="100%" height="400px">
                                    <Line data={data} options={options} />
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};

export default ECGAnalysisModal;
