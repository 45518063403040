import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography, Stack, Card, CardContent, CardActionArea, Link } from '@mui/material';
import { LocalHospital, HeartBroken } from '@mui/icons-material';

const Participate = () => {
  const { t, i18n } = useTranslation();

  // Liens pour chaque spécialité en fonction de la langue
  const formLinks = {
    fr: {
      infirmier_medecin: 'https://forms.gle/S65WFhT5kBY4gpeCA',
      cardiologue_rythmologue: 'https://forms.gle/FYBSMn3Sajk1niaQ7',
    },
    en: {
      infirmier_medecin: 'https://forms.gle/S65WFhT5kBY4gpeCA',
      cardiologue_rythmologue: 'https://forms.gle/FYBSMn3Sajk1niaQ7',
    },
  };

  // Récupération de la langue courante depuis i18n pour lier aux bons formulaires
  const lang = i18n.language;

  return (
    <Container sx={{ marginTop: 4, textAlign: 'center' }}>
      {/* Titre de la page */}
      <Typography variant="h4" gutterBottom>
        {t('participate.title')}
      </Typography>
      <Typography variant="h6" color="text.secondary">
        {t('participate.description')}
      </Typography>
      <Typography variant="h6" color="text.secondary">
        {t('participate.duration')}
      </Typography>

      {/* Sélection de la spécialité avec liens directs */}
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{ marginTop: 4 }}
      >
        {/* Lien pour Infirmiers / Médecins Généralistes */}
        <Card
          sx={{
            borderRadius: '12px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            transition: '0.3s',
            cursor: 'pointer',
            width: '320px',  // Largeur fixe
            height: '200px', // Hauteur fixe
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Centrer verticalement le contenu
          }}
        >
          <Link
            href={formLinks[lang].infirmier_medecin}
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CardActionArea>
              <CardContent sx={{ textAlign: 'center', padding: 2 }}>
                <LocalHospital fontSize="large" color="primary" />
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  {t('specialties.nurse_gp')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('participate.click_here')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>

        {/* Lien pour Cardiologues / Rythmologues */}
        <Card
          sx={{
            borderRadius: '12px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            transition: '0.3s',
            cursor: 'pointer',
            width: '320px',  // Largeur fixe
            height: '200px', // Hauteur fixe
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center', // Centrer verticalement le contenu
          }}
        >
          <Link
            href={formLinks[lang].cardiologue_rythmologue}
            underline="none"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CardActionArea>
              <CardContent sx={{ textAlign: 'center', padding: 2 }}>
                <HeartBroken fontSize="large" color="error" />
                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  {t('specialties.cardiologist')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {t('participate.click_here')}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Link>
        </Card>
      </Stack>
    </Container>
  );
};

export default Participate;
