import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Container,
  CircularProgress,
} from '@mui/material';
import {
  Person,
  Favorite,
  Warning,
} from '@mui/icons-material';
import { fetchPatients } from '../api/Patient';
import { fetchPatientECGs } from '../api/ECG';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';


const Dashboard = () => {
  const { t } = useTranslation();
  const [patients, setPatients] = useState([]);
  const [ecgCount, setEcgCount] = useState(0);
  const [normalSinusRhythmCount, setNormalSinusRhythmCount] = useState(0);
  const [atrialFibrillationCount, setAtrialFibrillationCount] = useState(0);
  const [otherECGCount, setOtherECGCount] = useState(0);
  const [loadingPatients, setLoadingPatients] = useState(true);
  const [loadingECGs, setLoadingECGs] = useState(true);
  const navigate = useNavigate();

  let userName = '';
  try {
    const token = Cookies.get('jwt');
    if (token) {
      const decodedToken = jwtDecode(token);
      userName = decodedToken.given_name ? decodedToken.given_name : 'Utilisateur';
    }
  } catch (error) {
    console.error('Erreur lors de la décodage du token JWT :', error);
  }

  useEffect(() => {
    const getPatients = async () => {
      try {
        const response = await fetchPatients();
        if (response && response.data) {
          const formattedData = response.data.map((patient, index) => ({
            id: patient.id || index, // Assurez-vous que chaque ligne ait un identifiant unique
            ...patient,
          }));
          setPatients(formattedData);
          await getECGs(formattedData);
        } else {
          console.error("Format inattendu des données :", response);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des patients :", error);
      } finally {
        setLoadingPatients(false);
      }
    };

    const getECGs = async (patients) => {
      try {
        let totalECGs = 0;
        let normalSinusRhythm = 0;
        let atrialFibrillation = 0;
        let otherECGs = 0;
        for (const patient of patients) {
          const response = await fetchPatientECGs(patient);
          if (response && response.data) {
            const ecgs = response.data;
            totalECGs += ecgs.length;
            ecgs.forEach(ecg => {
              if (ecg.ECGStatus === 'ClinicalStatus.NORMAL_SINUS_RHYTHM' || ecg.ECGStatus === 'ClinicalStatus.SINUS_TACHYCARDIA') {
                normalSinusRhythm++;
              } else if (ecg.ECGStatus === 'ClinicalStatus.ATRIAL_FIBRILLATION') {
                atrialFibrillation++;
              } else {
                otherECGs++;
              }
            });
          } else {
            console.error("Format inattendu des données ECG pour le patient :", patient);
          }
        }
        setEcgCount(totalECGs);
        setNormalSinusRhythmCount(normalSinusRhythm);
        setAtrialFibrillationCount(atrialFibrillation);
        setOtherECGCount(otherECGs);
        setLoadingECGs(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des ECGs :", error);
      }
    };

    getPatients();
  }, []);

  return (
    <Container
      maxWidth="lg"
      sx={{
        height: '100vh',
        bgcolor: 'linear-gradient(135deg, #f3e5f5 30%, #e8f5e9 90%)',
        p: 3,
      }}
    >

      <Typography variant="h5" align="center" sx={{ mb: 4, color: '#333'}}>
        {userName ? t('dashboard.welcome_message', { name: userName }) : t('dashboard.default_welcome_message')}
      </Typography>
      <Typography variant="h5" align="center" sx={{ mb: 4, color: '#333'}}>
        {t('dashboard.feel_at_home')}
      </Typography>

     

      <Box display="flex" justifyContent="space-around" flexWrap="wrap" sx={{ gap: 2 }}>
        <Box flexBasis={{ xs: '100%', md: '23%' }}>
          <QuickStatsCard
            title={t('dashboard.active_patients')}
            value={loadingPatients ? <CircularProgress size={24} /> : patients.length}
            icon={Person}
            color="#4caf50"
            onClick={() => navigate('/patients')}
          />
        </Box>
        <Box flexBasis={{ xs: '100%', md: '23%' }}>
          <QuickStatsCard
            title={t('dashboard.sinusal')}
            value={loadingECGs ? <CircularProgress size={24} /> : normalSinusRhythmCount}
            icon={Favorite}
            color="#4caf50"
            onClick={() => navigate('/ecg')}
          />
        </Box>
        <Box flexBasis={{ xs: '100%', md: '23%' }}>
          <QuickStatsCard
            title={t('ecg.status.ClinicalStatus.ATRIAL_FIBRILLATION')}
            value={loadingECGs ? <CircularProgress size={24} /> : atrialFibrillationCount}
            icon={Warning}
            color="#f44336"
            onClick={() => navigate('/ecg')}
          />
        </Box>
        <Box flexBasis={{ xs: '100%', md: '23%' }}>
          <QuickStatsCard
            title={t('dashboard.other_status')}
            value={loadingECGs ? <CircularProgress size={24} /> : otherECGCount}
            icon={Warning}
            color="#ff9800"
            onClick={() => navigate('/ecg')}
          />
        </Box>
      </Box>
    </Container>
  );
};

const QuickStatsCard = ({ title, value, icon: Icon, color, onClick }) => (
  <Card onClick={onClick} sx={{ bgcolor: color, color: 'white', p: 3, boxShadow: 3, borderRadius: 4, cursor: 'pointer' }}>
    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Icon sx={{ fontSize: 50, mb: 1 }} />
      <Typography variant="h5" sx={{ mb: 1 }}>{value}</Typography>
      <Typography variant="subtitle1" align="center">{title}</Typography>
    </CardContent>
  </Card>
);

export default Dashboard;