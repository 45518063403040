import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, IconButton } from '@mui/material';
import heroImage from '../assets/images/image.png';
import '../assets/css/styles.css';

function Home() {
  const { t } = useTranslation();

  const carouselItems = t('home.carousel', { returnObjects: true });

  
  return (
    <Box>
      {/* Hero Section */}
      <Box
        component="section"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mb: 5,
        }}
        className="hero"
      >
        <Box
          className="hero-content"
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          <Box
            component="img"
            src={heroImage}
            alt="Hero"
            className="hero-image"
            sx={{ mr: 3 }}
          />
          <Box>
            <Typography variant="h4" color="textPrimary">
              {t('home.heroTitle')}
            </Typography>
            <Typography variant="body1" color="textPrimary">
              {t('home.heroSubtitle')}
            </Typography>
          </Box>
        </Box>
      </Box>

      {/* Carousel Section */}
      <Box component="section" sx={{ py: 5 }}>
        <Box className="container">
          <Box
            id="carouselExampleIndicators"
            className="carousel slide"
            
          >
            {/* Indicateurs */}
            <Box component="ol" className="carousel-indicators">
              {carouselItems.map((_, index) => (
                <Box
                  component="li"
                  key={index}
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                  className={index === 0 ? 'active' : ''}
                />
              ))}
            </Box>

            {/* Éléments du carrousel */}
            <Box className="carousel-inner">
              {carouselItems.map((item, index) => (
                <Box
                  key={index}
                  className={`carousel-item ${index === 0 ? 'active' : ''}`}
                  sx={{ justifyContent: 'center', alignItems: 'center', py: 3 }}
                >
                  <Box className="icon-card" sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                    <Box
                      className="col-md-3"
                      sx={{
                        textAlign: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {index === 0 && <Box component={require('@mui/icons-material/Favorite').default} sx={{ fontSize: 50, color: 'primary.main' }} />}
{index === 1 && <Box component={require('@mui/icons-material/AccessTime').default} sx={{ fontSize: 50, color: 'primary.main' }} />}
{index === 2 && <Box component={require('@mui/icons-material/HealthAndSafety').default} sx={{ fontSize: 50, color: 'primary.main' }} />}
{index === 3 && <Box component={require('@mui/icons-material/MedicalServices').default} sx={{ fontSize: 50, color: 'primary.main' }} />}
{index === 4 && <Box component={require('@mui/icons-material/PrecisionManufacturing').default} sx={{ fontSize: 50, color: 'primary.main' }} />}
{index === 5 && <Box component={require('@mui/icons-material/HealthAndSafety').default} sx={{ fontSize: 50, color: 'primary.main' }} />}
                    </Box>
                    <Box className="col-md-9" sx={{ pl: 2 }}>
                      <Typography variant="h5" className="problem">
                        {item.problem}
                      </Typography>
                      <Typography variant="body1" className="solution">
                        {item.solution}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Box>

            {/* Contrôles du carrousel */}
            <IconButton
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
              sx={{ position: 'absolute', top: '50%', left: 0 }}
            >
              <Box component="span" className="carousel-control-prev-icon" aria-hidden="true" />
              <Typography component="span" className="sr-only">
                Previous
              </Typography>
            </IconButton>
            <IconButton
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
              sx={{ position: 'absolute', top: '50%', right: 0 }}
            >
              <Box component="span" className="carousel-control-next-icon" aria-hidden="true" />
              <Typography component="span" className="sr-only">
                Next
              </Typography>
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Home;